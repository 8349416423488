<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Nilai Ujian
              </span>
              <span
                class="text-muted mt-3 font-weight-bold font-size-sm"
                v-if="!isLoading && currentUjian.name"
                >{{ currentUjian.name }} -
                {{ removeHtmlTags(currentUjian.description) }}
              </span>
            </h3>
            <div class="card-toolbar">
              <a
                @click="exportNilai"
                class="btn btn-primary font-weight-bolder font-size-sm mr-1"
              >
                Export Nilai Akhir</a
              >
            </div>
          </div>

          <div class="card-body pt-4">
            <dropdown-level @selected="filterByRole" styles="mb-3 w-100" />
            <div
              class="d-flex justify-content-end mb-5 text-center"
              style="gap: 1rem"
              v-if="currentUjian.exam_type != 'ICES'"
            >
              <div class="bg-success pt-2 px-5 rounded">
                <p class="font-size-sm text-white">Mengerjakan</p>
                <p class="font-size-h2 text-white">
                  {{ items.filter((x) => x.is_done).length }}
                </p>
              </div>
              <div class="bg-danger pt-2 px-5 rounded">
                <p class="font-size-sm text-white">Tidak Mengerjakan</p>
                <p class="font-size-h2 text-white">
                  {{ items.filter((x) => x.is_done == false).length }}
                </p>
              </div>
              <div class="bg-info pt-2 px-5 rounded">
                <p class="font-size-sm text-white">Nilai Rata Rata</p>
                <p class="font-size-h2 text-white">
                  {{ parseFloat(avgScore).toFixed(2) }} /
                  {{ parseFloat(avgPrecentage).toFixed(2) }} %
                </p>
              </div>
            </div>
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :items="filter ? filtered : items"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada histori ujian ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada histori ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #cell(siswa)="data">
                <b-row class="align-items-center">
                  <b-col cols="auto">
                    <span class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <img
                        class="symbol-label"
                        src="/media/users/default.jpg"
                        alt=""
                      />
                    </span>
                  </b-col>
                  <b-col>
                    <a
                      class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >{{
                        data.item.student_id === undefined
                          ? "-"
                          : data.item.student_id.no_induk
                      }}
                    </a>
                    <span class="text-muted font-weight-bold d-block">{{
                      data.item.student_id === undefined
                        ? "-"
                        : data.item.student_id.full_name
                    }}</span>
                  </b-col>
                </b-row>
              </template>
              <template #cell(nilai)="data">
                <b-badge class="mr-1" pill href="#" variant="info">
                  <!-- {{ data.item.time }} Menit -->
                </b-badge>
                <span
                  v-if="data.item.scores != null"
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                >
                  {{ parseFloat(data.item.scores).toFixed(2) }} pts
                </span>
                <div v-else>-</div>
              </template>
              <template #cell(waktu)="data">
                <b-badge class="mr-1" pill href="#" variant="info">
                  <!-- {{ data.item.waktu }} Menit -->
                  {{ data.item.remaining_time }} Menit
                </b-badge>
              </template>
              <template #cell(tanggal)="data">
                <span
                  class="badge font-weight-bold py-2 bg-light-primary"
                  v-if="data.item.updatedAt && data.item.remaining_time != 0"
                >
                  {{ data.item.updatedAt | moment("dddd, LL HH:mm") }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(level_1)="row">
                <span>{{ row.item.level_1 ?? "-" }}</span>
              </template>
              <template #cell(level_2)="row">
                <span>{{ row.item.level_2 ?? "-" }}</span>
              </template>
              <template #cell(level_3)="row">
                <span>{{ row.item.level_3 ?? "-" }}</span>
              </template>
              <template #cell(level_4)="row">
                <span>{{ row.item.level_4 ?? "-" }}</span>
              </template>
              <template #cell(level_5)="row">
                <span>{{ row.item.level_5 ?? "-" }}</span>
              </template>

              <template #cell(scores)="row">
                <span
                  :class="{
                    'text-danger': row.item.scores < 0,
                  }"
                  >{{
                    isAlreadyDone(row.item)
                      ? parseFloat(row.item.scores).toFixed(2)
                      : "N/A"
                  }}
                  /
                  {{ isAlreadyDone(row.item) ? row.item.percentage : "N/A" }}
                  %</span
                >
              </template>
              <template #cell(integrity)="row">
                {{
                  isAlreadyDone(row.item) &&
                  row.item.ices &&
                  row.item.ices.length > 0
                    ? row.item.ices[0].integrity
                      ? row.item.ices[0].integrity + "%"
                      : "0" + "%"
                    : "N/A"
                }}
              </template>

              <template #cell(customer_satisfaction)="row">
                {{
                  isAlreadyDone(row.item)
                    ? `${row.item.customer_satisfaction} %`
                    : "N/A"
                }}
              </template>

              <template #cell(excellence)="row">
                {{
                  isAlreadyDone(row.item) &&
                  row.item.ices &&
                  row.item.ices.length > 0
                    ? row.item.ices[0].excellence
                      ? row.item.ices[0].excellence + "%"
                      : "0" + "%"
                    : "N/A"
                }}
              </template>

              <template #cell(spirituality)="row">
                {{
                  isAlreadyDone(row.item) &&
                  row.item.ices &&
                  row.item.ices.length > 0
                    ? row.item.ices[0].spirituality
                      ? row.item.ices[0].spirituality + " %"
                      : "0" + "%"
                    : "N/A"
                }}
              </template>

              <template #cell(avg_score)="row">
                {{ row.item.avg_score + " %" }}
              </template>

              <template #cell(rank)="row"> {{ row.item.rank }} </template>

              <template #cell(action)="data">
                <router-link
                  :to="{
                    name: 'review-exam',
                    params: {
                      examId: data.item.exam_id._id,
                      employeeId: data.item.student_id._id,
                    },
                  }"
                  href="#"
                  class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                &nbsp;
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalImport"
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Export"
      cancel-title="Batalkan"
      centered
      @ok="exportNilai"
      title="Export nilai"
    >
      <b-row>
        <b-col
          cols="4"
          v-for="(kelas, i) in currentUjian.class_id"
          :key="`${kelas.class_name}${i}`"
        >
          <b-button
            @click="selectedExport = `${kelas._id}`"
            :class="`btn ${
              selectedExport == kelas._id ? 'btn-success' : 'btn-primary'
            }  font-weight-bolder font-size-sm mr-1 w-100`"
          >
            {{ kelas.class_name }}
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
            @click="selectedExport = `all`"
            :class="`btn ${
              selectedExport == `all` ? 'btn-success' : 'btn-primary'
            }  font-weight-bolder font-size-sm mr-1 w-100`"
          >
            Semua Kelas
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_NILAI_SISWA } from "@/core/services/store/rekapnilai.module";
import mixin from "@/core/services/Helpers";

import DropdownLevel from "@/view/pages/summary/components/DropdownLevel.vue";

import {
  GET_LIST_RESULT_EXAM,
  GET_ONE_UJIAN,
  GET_FILE_RESULT_EXAM,
  URL_EXPORT,
} from "@/core/services/store/ujian.module";

export default {
  name: "NilaiSiswaList",
  mixins: [mixin],
  components: {
    DropdownLevel,
  },
  data() {
    return {
      modalImport: false,
      items: [],
      filtered: [],
      origin: [],
      selectedExport: "all",
      isLoading: false,
      tabIndex: 0,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      maxScores: 0,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "siswa",
          label: "Pegawai",
          sortable: true,
        },
        {
          key: "level_1",
          label: "Direktorat",
          sortable: true,
        },
        {
          key: "level_2",
          label: "Divisi/Region",
          sortable: true,
        },
        {
          key: "level_3",
          label: "Departemen/Cabang",
          sortable: true,
        },
        {
          key: "level_4",
          label: "Supervisor",
          sortable: true,
        },
        {
          key: "level_5",
          label: "Staff",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu Pengerjaan",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal Pengerjaan",
          sortable: true,
        },
        {
          key: "integrity",
          label: "Integrity",
          sortable: true,
        },
        {
          key: "customer_satisfaction",
          label: "Customer Satisfaction",
          sortable: true,
        },
        {
          key: "excellence",
          label: "Excellence",
          sortable: true,
        },
        {
          key: "spirituality",
          label: "Spirituality",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
        },
      ],
      fieldsNilai: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "siswa",
          label: "Pegawai",
          sortable: true,
        },
        {
          key: "level_1",
          label: "Direktorat",
          sortable: true,
        },
        {
          key: "level_2",
          label: "Divisi/Region",
          sortable: true,
        },
        {
          key: "level_3",
          label: "Departemen/Cabang",
          sortable: true,
        },
        {
          key: "level_4",
          label: "Supervisor",
          sortable: true,
        },
        {
          key: "level_5",
          label: "Staff",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu Pengerjaan",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal Pengerjaan",
          sortable: true,
        },
        {
          key: "scores",
          label: "score",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
        },
      ],
      table: {
        sortBy: null,
        orderBy: null,
      },
      filteredRole: [],
      selectedMapel: null,
      avg_scores: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Nilai Ujian" }]);
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters([
      "currentRekapNilai",
      "currentUjianResult",
      "currentUjian",
      "CurrentUser",
    ]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.currentUser.user.level == 1;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
    avgScore() {
      let totalScore = this.items.reduce((sum, item) => sum + item.scores, 0);
      let avg = totalScore / this.items.filter((x) => x.is_done).length;
      return isNaN(avg) ? 0.0 : avg;
    },
    avgPrecentage() {
      let avg = (this.avgScore / this.maxScores) * 100;
      return isNaN(avg) ? 0.0 : avg;
    },
  },
  watch: {
    tabIndex() {
      const currentClassId = this.currentUjian.class_id[this.tabIndex]._id;
      this.items = this.currentUjianResult.filter((item) =>
        item.student_id.class_id.includes(currentClassId)
      );
      this.totalRows = this.items.length;
    },
    filter() {
      let search = this.filter;
      if (search) {
        this.filtered = this.items.filter(
          (item) =>
            item.student_id.no_induk
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.student_id.full_name
              .toLowerCase()
              .includes(search.toLowerCase())
        );
        this.totalRows = this.filtered.length;
      } else {
        this.totalRows = this.items.length;
      }

      this.currentPage = 1;
    },
    // "table.orderBy": function (firstNew, oldNew) {
    //   let key = this.table.sortBy;
    //   if (key === "siswa") {
    //     this.items = this.items.sort(
    //       (a, b) =>
    //         Number(a.student_id.no_induk) - Number(b.student_id.no_induk)
    //     );
    //   }
    // },
  },
  methods: {
    chooseExport(item) {
      this.selectedExport = item;
    },
    exportNilai() {
      const params = new URLSearchParams();

      var fileName = `${this.currentUjian.name}.xlsx`;

      var token = this.currentUser.token;

      if (typeof token === "undefined") {
        token = this.currentUser.access_token;
      }

      params.append("exam_id", this.idUjian);
      params.append("token", token);
      params.append("access", this.filteredRole.join(","));

      window.location.href = `${URL_EXPORT}${params.toString()}`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then((data) => {
          this.isLoading = false;
          if (data.data && data.data.exam_type == "Default") {
            this.fields = this.fieldsNilai;
            let answers = [].concat(
              ...data.data.question_pack_id.questions.map((x) => x.answer)
            );

            this.maxScores = data?.data?.question_pack_id?.max_scores ?? 0;
            // this.maxScores = answers
            //   .filter((x) => x.point > 0)
            //   .map((x) => x.point)
            //   .reduce((a, b) => a + b, 0);
          }

          this.getDataNilai();
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getDataNilai() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_LIST_RESULT_EXAM, this.idUjian)
        .then((response) => {
          this.isLoading = false;

          this.items = this.currentUjianResult;
          this.origin = this.currentUjianResult;
          this.totalRows = this.items.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    isAlreadyDone(params) {
      return params.is_done;
    },
    filterByRole(role = []) {
      try {
        this.filteredRole = role;
        if (role.length == 0) {
          this.items = [...this.origin];
          this.totalRows = this.origin.length;
          return;
        }

        let items = [...this.origin];

        items = items.filter((x) => {
          let isRoleMatch = this.isRoleMatch(
            x.student_id.user_id.actual_access,
            role
          );
          return isRoleMatch;
        });
        this.items = items;
        this.totalRows = this.items.length;
      } catch (e) {
        console.log("error on Nilai List :" + e);
      }
    },
    isRoleMatch(user_role = [], system_role = []) {
      let isExist = false;
      user_role.forEach((user) => {
        system_role.forEach((system) => {
          if (user == system) isExist = true;
        });
      });
      return isExist;
    },
  },
};
</script>

<style scoped></style>
